import './src/layout/reset.scss'
import './src/layout/global.scss'

export const onClientEntry = () => {
  if (!('IntersectionObserver' in window)) {
    import('intersection-observer')
  }
}

const style = document.documentElement.style

const sizeListener = () => style.setProperty('--window-height', window.innerHeight + 'px')
sizeListener()
// window.addEventListener('resize', sizeListener)
